import { FunctionComponent } from "react";
import HeroContainer from "../components/HeroContainer";
import BodyContainer from "../components/BodyContainer";
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
  return (
    <div className={styles.home}>
      <div className={styles.vGrid} />
      <HeroContainer />
      <div className={styles.spacer}>
        <img className={styles.sketchIcon} alt="" src="/sketch@2x.png" />
      </div>
      <BodyContainer />
      <section className={styles.footer}>
        <div className={styles.footerwrapper}>
          <div className={styles.footerwrappercontent}>
            <h2 className={styles.footertitle}>ABOUT ROEL</h2>
            <p className={styles.footerbodytext}>
              Meet Roel, a pioneering public speaker with a keen understanding
              of the latest trends and developments in areas such as artificial
              intelligence, blockchain, and Web3.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
