import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./CardContainer01.module.css";

type CardContainer01Type = {
  rowImage?: string;
  expertiseText?: string;

  /** Style props */
  propBorderBottom?: CSSProperties["borderBottom"];
};

const CardContainer01: FunctionComponent<CardContainer01Type> = ({
  rowImage,
  expertiseText,
  propBorderBottom,
}) => {
  const rowContentStyle: CSSProperties = useMemo(() => {
    return {
      borderBottom: propBorderBottom,
    };
  }, [propBorderBottom]);

  return (
    <section className={styles.rowcontent} style={rowContentStyle}>
      <div className={styles.rowcontentclmn}>
        <img className={styles.imageIcon} alt="" src={rowImage} />
      </div>
      <div className={styles.rowcontentclmn1}>
        <p className={styles.asAnExpert}>{expertiseText}</p>
      </div>
    </section>
  );
};

export default CardContainer01;
