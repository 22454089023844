import { FunctionComponent } from "react";
import styles from "./CardContainer02.module.css";

const CardContainer02: FunctionComponent = () => {
  return (
    <section className={styles.rowcontent}>
      <div className={styles.rowcontentclmn}>
        <p className={styles.inTheFastPaced}>
          In the fast-paced world of AI and Web3, staying ahead of the curve is
          essential. That's why my company workshops focus on providing
          innovative solutions and creative strategies for tackling the
          challenges of today's digital landscape. Whether you're looking to
          improve your team's technical skills, develop new products and
          services, or leverage emerging technologies like blockchain and NFTs,
          my customized workshops can help you achieve your goals.
        </p>
      </div>
      <div className={styles.rowcontentclmn1}>
        <img className={styles.imageIcon} alt="" src="/Image02@2x.png" />
      </div>
    </section>
  );
};

export default CardContainer02;
