import { FunctionComponent, useRef, useState, useEffect } from "react";
import CardContainer01 from "./CardContainer01";
import CardContainer02 from "./CardContainer02";
import styles from "./BodyContainer.module.css";

const BodyContainer: FunctionComponent = () => {

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [titleCopy, setTitleCopy] = useState('loading ..');
  const [percentage, setPercentage] = useState(0);

  const calculateVisibility = () => {
    const element = elementRef.current;

    if (element) {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const elementPositionPercentage = Math.floor(((viewportHeight - rect.top) / viewportHeight) * 100);
      changeTitleColor(elementPositionPercentage);
      console.log(elementPositionPercentage);
    }
  };

  const changeTitleColor = (percentage: number = 0) => {
    if(percentage >= 0 || percentage <= 100) {
      const copy = `PUBLIC SPEAKING, WORKSHOPS AND 1:1 SESSIONS.`;
      const copyCharacterCount = copy.length;
      const charactersPerPercent = copyCharacterCount / 100;

      const splitIndex = Math.min(copyCharacterCount, Math.ceil(percentage * charactersPerPercent));
      const part1 = copy.substring(0, splitIndex);
      const part2 = copy.substring(splitIndex);

      setTitleCopy(`<span style="color: #000"}>${part1}</span>${part2}`);
    }
  }

  useEffect(() => {

    calculateVisibility();
    window.addEventListener('scroll', calculateVisibility);
    return () => {
      window.removeEventListener('scroll', calculateVisibility);
    };

  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.bodytitle}>
        <div className={styles.bodytitleclmn}>
          <h2 className={styles.publicSpeakingInCompanyContainer}>
            <p className={styles.publicSpeaking} ref={elementRef} dangerouslySetInnerHTML={{ __html: titleCopy }} />
          </h2>
        </div>
      </div>
      <CardContainer01
        rowImage="/Image01@2x.png"
        expertiseText="As an expert in AI and Web3, I bring a unique perspective to public speaking that emphasizes the intersection of technology and creativity. Whether you're hosting a conference or a networking event, I can help you engage your audience with cutting-edge insights and pioneering ideas."
      />
      <CardContainer02 />
      <CardContainer01
        rowImage="/Image03@2x.png"
        expertiseText="For professionals looking to pioneer innovation at the intersection of technology and creativity, my 1:1 knowledge sessions offer personalized guidance and mentorship. As an experienced coach and mentor, I can help you develop your skills in AI and Web3, refine your creative process, and build your confidence as a pioneering innovator. My sessions are focused on providing practical advice and actionable strategies for leveraging the latest advancements in technology to achieve your goals."
        propBorderBottom="unset"
      />
    </div>
  );
};

export default BodyContainer;
