import { FunctionComponent, useRef, useEffect, useState } from "react";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import styles from "./HeroContainer.module.css";

const HeroContainer: FunctionComponent = () => {

  const elementRef = useRef<HTMLDivElement | null>(null);
  const lottiePlayer = useRef<Player | null>(null);

  const [intro, setIntro] = useState('loading ..');
  const [percentage, setPercentage] = useState(0);

  const handleScrollToTop = () => {
    if (window.scrollY === 0) {
      if (lottiePlayer.current) {
        lottiePlayer.current.play();
      }
    }
  };

  const calculateVisibility = () => {
    const element = elementRef.current;

    if (element) {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const elementPositionPercentage = Math.floor(((viewportHeight - rect.top) / viewportHeight) * 100);
      changeCopyColor(elementPositionPercentage);
    }
  };

  const changeCopyColor = (percentage: number = 0) => {
    if(percentage >= 0 || percentage <= 100) {
      const copy = `If you're still figuring out how to work with AI ethically, paddle harder to catch this wave; or use me to push you forward.`;
      const copyCharacterCount = copy.length;
      const charactersPerPercent = copyCharacterCount / 100;

      const splitIndex = Math.min(copyCharacterCount, Math.ceil(percentage * charactersPerPercent));
      const part1 = copy.substring(0, splitIndex);
      const part2 = copy.substring(splitIndex);

      setIntro(`<span style="color: #FFF"}>${part1}</span>${part2}`);
    }
  }

  useEffect(() => {

    calculateVisibility();
    window.addEventListener('scroll', calculateVisibility);
    // window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', calculateVisibility);
      // window.removeEventListener('scroll', handleScrollToTop);
    };

  }, []);

  return (
    <div className={styles.hero}>
      <div className={styles.herologo}>
        <div className={styles.logoRoelAi}>
          <Player
            ref={lottiePlayer} // set the ref to your class instance
            autoplay={true}
            loop={false}
            controls={false}
            keepLastFrame={true}
            src="/logoAnim.json"
          ></Player>
        </div>
      </div>
      <div className={styles.herocontent}>
        <h1 className={styles.theGeekSpeaks}>THE GEEK SPEAKS AND INSPIRES</h1>
        <div className={styles.herocontenttext}>
          <p className={styles.ifYoureStillContainer} ref={elementRef} dangerouslySetInnerHTML={{ __html: intro }} />
        </div>
      </div>
    </div>
  );
};

export default HeroContainer;
